import { Buffer } from "buffer";
import process from "process";

// Use the polyfills from the vite.config.ts
window.process = process;
window.Buffer = Buffer;

(window as any).global = window;

// from here: https://gist.github.com/lmammino/ef121da874a80d657379a1cd64bf8166?permalink_comment_id=5298953#gistcomment-5298953
if (Promise && typeof (Promise as any).withResolvers === "undefined") {
    (Promise as any).withResolvers = <T>() => {
        let resolve: (value: T | PromiseLike<T>) => void;
        let reject: (reason?: unknown) => void;
        const promise = new Promise<T>((res, rej) => {
            resolve = res;
            reject = rej;
        });
        return { promise, resolve: resolve!, reject: reject! };
    };
}
