import * as Routes from "@farmact/model/src/constants/farmActAppRoutes";

export function getPageTitleFromRoute(route: string) {
    switch (route) {
        case Routes.LANDING:
            return "Home";
        case Routes.HOME:
            return "Home";
        case Routes.ACCOUNT:
            return "Kontoverwaltung";
        case Routes.SETTINGS:
            return "Einstellungen";
        case Routes.CONTRACTORS:
            return "Dienstleister";
        case Routes.ORDERS_OVERVIEW:
            return "Aufträge";
        case Routes.ORDER_DISPOSITION:
            return "Disposition";
        case Routes.ORDER_SETTINGS:
            return "Auftragseinstellungen";
        case Routes.RECEIPTS:
            return "Belege";
        case Routes.BILLS:
            return "Rechnungen";
        case Routes.INCOMING_BILLS:
            return "Eingangsrechnungen";
        case Routes.COST_ACCOUNTS:
            return "Rechnungs Kategorien";
        case Routes.BILL_SETTINGS:
            return "Rechnungseinstellungen";
        case Routes.EMPLOYEES_MAP:
            return "Mitarbeiterkarte";
        case Routes.ORGANIZATION:
            return "Stammdaten";
        case Routes.ORGANIZATION_EMPLOYEES:
            return "Mitarbeiter";
        case Routes.ORGANIZATION_EMPLOYEES_TIME_TRACKING:
            return "Arbeitszeiten";
        case Routes.ORGANIZATION_MACHINES:
            return "Maschinen";
        case Routes.ORGANIZATION_CUSTOMERS:
            return "Kunden";
        case Routes.ORGANIZATION_LOADING_POINTS:
            return "Be-/Entladestellen";
        case Routes.ORGANIZATION_RESOURCES:
            return "Artikel";
        case Routes.ORGANIZATION_SERVICES:
            return "Dienstleistungen";
        case Routes.ORGANIZATION_RENTAL_SERVICES:
            return "Vermietungen konfigurieren";
        case Routes.RENTAL_ORDER_CREATE:
            return "Vermietungsauftrag";
        case Routes.ORGANIZATION_GASSTATIONS:
            return "Tankstellen";
        case Routes.ORGANIZATION_SUPPLIERS:
            return "Lieferanten";
        case Routes.ORGANIZATION_COMPANY:
            return "Stammdaten meines Betriebs";
        case Routes.TASKS:
            return "Meine Aufträge";
        case Routes.INTERNAL_WORK:
            return "Interne Arbeiten";
        case Routes.TIME_TRACKING:
            return "Meine Arbeitszeiten";
        case Routes.UPDATES_INFO:
            return "Updateliste";
        case Routes.DELIVERY_NOTES:
            return "Lieferscheine";
        case Routes.OFFERS:
            return "Angebote";
        case Routes.OFFERS_ARCHIVE:
            return "Archivierte Angebote";
        case Routes.CREDIT_NOTES:
            return "Gutschriften";
        case Routes.ANALYSIS_REVENUE:
            return "Umsatzanalyse";
        case Routes.REPORTS:
            return "Berichte & Analysen";
        case Routes.PROJECTS:
            return "Projekte";
        case Routes.CAMPAIGNS:
            return "Auftragsketten";
        case Routes.TOLL:
            return "Mautimport";
        case Routes.ORGANIZATION_RESOURCES_TAGS:
            return "Artikelkategorien";
        case Routes.ORGANIZATION_CUSTOMER_TAGS:
            return "Kundenkategorien";
        case Routes.ORGANIZATION_MASCHINENRINGS:
            return "Maschinenringe";
        case Routes.ORGANIZATION_PRICE_AGREEMENTS:
            return "Preisvereinbarungen";
    }
    if (route.indexOf(Routes.CONTRACTORS + "/") >= 0) {
        return "Kundendetails";
    }
    if (route.indexOf(Routes.ORDERS_ARCHIVE) >= 0) {
        return "Archivierte Aufträge";
    }
    if (route.indexOf(Routes.ORDERS_OVERVIEW + "/") >= 0) {
        return "Auftragsmanagement";
    }
    if (route.indexOf(Routes.BILL_ARCHIVE) >= 0) {
        return "Archivierte Rechnungen";
    }
    if (route.includes("billReminders")) {
        return "Mahnungen";
    }
    if (route.includes("cancellation")) {
        return "Stornorechnung";
    }
    if (route.indexOf(Routes.BILLS + "/") >= 0) {
        return "Rechnungsdetails";
    }
    if (route.indexOf(Routes.INCOMING_BILLS + "/") >= 0) {
        return "Eingangsrechnungsdetails";
    }
    if (route.indexOf(Routes.DELIVERY_NOTES_ARCHIVE) >= 0) {
        return "Genehmigte Lieferscheine";
    }
    if (route.indexOf(Routes.DELIVERY_NOTES_SETTINGS) >= 0) {
        return "Einstellungen: Lieferscheine";
    }
    if (route.indexOf(Routes.DELIVERY_NOTES + "/") >= 0) {
        return "Lieferscheindetails";
    }
    if (route.indexOf(Routes.OFFERS_SETTINGS) >= 0) {
        return "Einstellungen: Angebote";
    }
    if (route.indexOf(Routes.OFFERS + "/") >= 0) {
        return "Angebotsdetails";
    }
    if (route.indexOf(Routes.CREDIT_NOTES_SETTINGS) >= 0) {
        return "Einstellungen: Gutschriften";
    }
    if (route.indexOf(Routes.CREDIT_NOTES + "/") >= 0) {
        return "Gutschriftsdetails";
    }
    if (route.indexOf(Routes.ORGANIZATION_MACHINES + "/") >= 0) {
        return "Maschinendetails";
    }
    if (route.indexOf(Routes.ORGANIZATION_EMPLOYEES + "/") >= 0) {
        return "Mitarbeiterdetails";
    }
    if (route.indexOf(Routes.ORGANIZATION_MASCHINENRINGS + "/") >= 0) {
        return "Maschinenringdetails";
    }
    if (route.indexOf(Routes.ORGANIZATION_PRICE_AGREEMENTS + "/") >= 0) {
        return "Preisvereinbarungdetails";
    }
    if (route.indexOf(Routes.ORGANIZATION_CUSTOMERS + "/") >= 0) {
        return "Kundendetails";
    }
    if (route.indexOf(Routes.ORGANIZATION_RESOURCES + "/") >= 0) {
        return "Artikeldetails";
    }
    if (route.indexOf(Routes.RENTAL_ORDERS + "/") >= 0) {
        return "Auftragsmanagement";
    }
    if (route.indexOf(Routes.ORGANIZATION_SERVICES_CATALOG) >= 0) {
        return "Dienstleistungsvorlagen";
    }
    if (route.indexOf(Routes.ORGANIZATION_SERVICES + "/") >= 0) {
        return "Dienstleistung Detailansicht";
    }
    if (route.indexOf(Routes.ORGANIZATION_SUPPLIERS + "/") >= 0) {
        return "Lieferantendetails";
    }
    if (route.indexOf(Routes.TASKS + "/") >= 0) {
        return "Auftrag";
    }
    if (route.indexOf(Routes.UPDATES_INFO + "/") >= 0) {
        return "Update Info";
    }
    if (route.indexOf(Routes.PROJECTS + "/") >= 0) {
        return "Projektdetails";
    }
    if (route.indexOf(Routes.CAMPAIGNS + "/") >= 0) {
        return "Auftragskettendetails";
    }
    if (route.indexOf(Routes.ORGANIZATION_GASSTATIONS + "/") >= 0) {
        return "Tankstellendetails";
    }
    return "FarmAct";
}

export function isRouteTopLevel(route: string) {
    switch (route) {
        case Routes.LANDING:
        case Routes.HOME:
        case Routes.ACCOUNT:
        case Routes.SETTINGS:
        case Routes.ORDERS_OVERVIEW:
        case Routes.RECEIPTS:
        case Routes.BILLS:
        case Routes.DELIVERY_NOTES:
        case Routes.CREDIT_NOTES:
        case Routes.OFFERS:
        case Routes.INCOMING_BILLS:
        case Routes.EMPLOYEES_MAP:
        case Routes.ORGANIZATION:
        case Routes.ORGANIZATION_MACHINES:
        case Routes.ORGANIZATION_EMPLOYEES:
        case Routes.ORGANIZATION_CUSTOMERS:
        case Routes.ORGANIZATION_LOADING_POINTS:
        case Routes.ORGANIZATION_RESOURCES:
        case Routes.ORGANIZATION_SERVICES:
        case Routes.ORGANIZATION_COMPANY:
        case Routes.INTERNAL_WORK:
        case Routes.TIME_TRACKING:
        case Routes.ORGANIZATION_GASSTATIONS:
        case Routes.TASKS:
        case Routes.UPDATES_INFO:
        case Routes.CONTRACTORS:
        case Routes.CONTRACTORS_ORDER_REQUESTS:
        case Routes.REPORTS:
            return true;
        default:
            return false;
    }
}

export function getBackLinkForDetailPage(route: string) {
    if (route.indexOf(Routes.CONTRACTORS + "/") >= 0) {
        return Routes.CONTRACTORS;
    }
    if (route.indexOf(Routes.ORDERS_OVERVIEW + "/") >= 0) {
        return Routes.ORDERS_OVERVIEW;
    }
    if (route.indexOf(Routes.BILLS + "/") >= 0) {
        return Routes.BILLS;
    }
    if (route.indexOf(Routes.DELIVERY_NOTES + "/") >= 0) {
        return Routes.DELIVERY_NOTES;
    }
    if (route.indexOf(Routes.OFFERS + "/") >= 0) {
        return Routes.OFFERS;
    }
    if (route.indexOf(Routes.ORGANIZATION_MACHINES + "/") >= 0) {
        return Routes.ORGANIZATION_MACHINES;
    }
    if (route.indexOf(Routes.ORGANIZATION_EMPLOYEES + "/") >= 0) {
        return Routes.ORGANIZATION_EMPLOYEES;
    }
    if (route.indexOf(Routes.ORGANIZATION_RESOURCES + "/") >= 0) {
        return Routes.ORGANIZATION_RESOURCES;
    }
    if (route.indexOf(Routes.ORGANIZATION_CUSTOMERS + "/") >= 0) {
        return Routes.ORGANIZATION_CUSTOMERS;
    }
    if (route.indexOf(Routes.RENTAL_ORDERS + "/") >= 0) {
        return Routes.RENTAL_ORDERS;
    }
    if (route.indexOf(Routes.ORGANIZATION_SERVICES + "/") >= 0) {
        return Routes.ORGANIZATION_SERVICES;
    }
    if (route.indexOf(Routes.ORGANIZATION_RENTAL_SERVICES) >= 0) {
        return Routes.ORGANIZATION_SERVICES;
    }
    if (route.indexOf(Routes.TASKS + "/") >= 0) {
        return Routes.TASKS;
    }
    if (route.indexOf(Routes.UPDATES_INFO + "/") >= 0) {
        return Routes.UPDATES_INFO;
    }
    if (route.indexOf(Routes.PROJECTS + "/") >= 0) {
        return Routes.PROJECTS;
    }
    if (route.indexOf(Routes.PROJECTS) >= 0) {
        return Routes.ORDERS_OVERVIEW;
    }
    if (route.indexOf(Routes.TOLL) >= 0) {
        return Routes.ORDERS_OVERVIEW;
    }

    return Routes.HOME;
}
