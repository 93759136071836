import { OrganizationContextType } from "@/components/organization/context/useOrganizationContext";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import { useCompanyId } from "./useCompanyId";

export function useFetchOrganizationContextData(): OrganizationContextType {
    const companyId = useCompanyId();

    const [machines, machinesLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllMachines() : undefined,
        [companyId]
    );
    const [services, servicesLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllServices() : undefined,
        [companyId]
    );
    const [resources, resourcesLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllResources() : undefined,
        [companyId]
    );
    const [gasStations, gasStationsLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllGasStations() : undefined,
        [companyId]
    );
    const [employees, employeesLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllEmployees() : undefined,
        [companyId]
    );
    const [loadingPoints, loadingPointsLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllCommonLoadingOrUnloadingPoints() : undefined,
        [companyId]
    );

    const [resourceTags, resourceTagsLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllResourceTags() : undefined,
        [companyId]
    );
    const [customerTags, customerTagsLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllCustomerTags() : undefined,
        [companyId]
    );

    const [suppliers, suppliersLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllSuppliers() : undefined,
        [companyId]
    );

    const [maschinenrings, maschinenringsLoading] = useCollectionData(
        companyId ? Firebase.instance().getAllMaschinenrings({ include: "non-archived" }) : undefined,
        [companyId]
    );

    return {
        machines,
        services,
        resources,
        gasStations,
        employees,
        resourceTags,
        loadingOrUnloadingPoints: loadingPoints,
        customerTags,
        suppliers,
        maschinenrings,
        //
        machinesLoading,
        servicesLoading,
        resourcesLoading,
        gasStationsLoading,
        employeesLoading,
        loadingPointsLoading,
        resourceTagsLoading,
        customerTagsLoading,
        suppliersLoading,
        maschinenringsLoading,
    };
}
