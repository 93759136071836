import { Capacitor } from "@capacitor/core";
import { IonButton, IonPage, IonSpinner } from "@ionic/react";
import { ReactNode, Suspense } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Hint } from "@/components/hint/Hint";
import { useNetworkStatus } from "@/util/customHooks/useNetworkStatus";
import { recordError } from "@/util/recordError";
import "./defaultPage.scss";

type PageContentProps = {
    children: ReactNode;
};

/**
 * Use this component as a root for pages. It handles page transition animations as well as error boundaries for lazily loaded content.
 * @param props
 * @constructor
 */
export function DefaultPage(props: PageContentProps) {
    return (
        <IonPage>
            <ErrorBoundary
                fallbackRender={Fallback}
                onError={(error: any, info: any) => {
                    if (error.message.includes(messagePartForFailedLazyLoading)) {
                        return;
                    }
                    recordError("Caught error", { error, info });
                }}>
                <Suspense fallback={<IonSpinner />}>{props.children}</Suspense>
            </ErrorBoundary>
        </IonPage>
    );
}

function Fallback(props: FallbackProps) {
    const isOnline = useNetworkStatus();
    if (props.error.message.includes(messagePartForFailedLazyLoading)) {
        return (
            <Hint color="primary" severity="info" className="default-page__error-hint">
                <div className="default-page__reload-message">
                    <p>Es gibt eine neue Version von FarmAct!</p>
                    <p>Bitte lade die Seite neu (Strg / Cmd + R).</p>
                    <div>
                        <IonButton onClick={() => location.reload()}>Jetzt neu laden</IonButton>
                    </div>
                </div>
            </Hint>
        );
    }

    const message = (function () {
        if (Capacitor.isNativePlatform()) {
            return "Da hat etwas nicht funktioniert. Bitte starte die App neu.";
        }
        if (!isOnline) {
            return "Du hast gerade keine Internet-Verbindung. Bitte lade die Seite neu, sobald du wieder online bist.";
        }
        return "Da hat etwas nicht funktioniert. Bitte lade die Seite neu.";
    })();
    return (
        <Hint color="danger" severity="error" className="default-page__error-hint">
            {message}
        </Hint>
    );
}

const messagePartForFailedLazyLoading = "Failed to fetch dynamically imported module";
