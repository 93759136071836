import { AppUser } from "@farmact/model/src/model/AppUser";
import { useEffect } from "react";
import { appVersion } from "@/config/config";
import { Firebase } from "@/firebase";
import { useDeviceInfo } from "@/util/customHooks/useDeviceInfo";

export function useReportDiagnostics(appUserId: AppUser["id"] | undefined) {
    const deviceInfo = useDeviceInfo();
    useEffect(() => {
        if (!appUserId) {
            return;
        }
        const updateDiagnostics = async () => {
            const update: Partial<AppUser> = {
                lastSeenAppVersion: appVersion,
            };
            if (deviceInfo) {
                update.latestDeviceInfo = deviceInfo;
            }
            Firebase.instance().updateAppUser(appUserId, update);
        };
        updateDiagnostics();
    }, [appUserId, deviceInfo]);
}
