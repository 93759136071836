import { ResourceUnit } from "@farmact/model/src/model/Resource";
import { getSingularPluralTextFormatter } from "@farmact/utils/src/formatters";

export const getResourceCostUnitName = (costUnit: ResourceUnit, count = 0) => {
    switch (costUnit) {
        case ResourceUnit.KILOMETER:
            return "Kilometer";
        case ResourceUnit.LEGACY_LITER:
            return "Liter";
        case ResourceUnit.LEGACY_KILOGRAM:
            return "Kilogramm";
        case ResourceUnit.LEGACY_PIECE:
            return "Stück";
        case ResourceUnit.LEGACY_CARTRIDGE:
            return getSingularPluralTextFormatter("Kartusche", "Kartuschen")(count);
        case ResourceUnit.LITER:
            return "Liter";
        case ResourceUnit.MILLILITER:
            return "Milliliter";
        case ResourceUnit.HECTOLITER:
            return "Hektoliter";
        case ResourceUnit.KILOGRAM:
            return "Kilogramm";
        case ResourceUnit.GRAM:
            return "Gramm";
        case ResourceUnit.PIECE:
            return "Stück";
        case ResourceUnit.CARTRIDGE:
            return getSingularPluralTextFormatter("Kartusche", "Kartuschen")(count);
        case ResourceUnit.METER:
            return "Meter";
        case ResourceUnit.SEED:
            return "Körner";
        case ResourceUnit.TON:
            return getSingularPluralTextFormatter("Tonne", "Tonnen")(count);
        case ResourceUnit.CUBIC_METER:
            return "Kubikmeter";
        case ResourceUnit.SPACE_CUBIC_METER:
            return "Raummeter";
        case ResourceUnit.BULK_CUBIC_METER:
            return "Schüttraummeter";
        case ResourceUnit.HOUR:
            return getSingularPluralTextFormatter("Stunde", "Stunden")(count);
        case ResourceUnit.DAY:
            return getSingularPluralTextFormatter("Tag", "Tage")(count);
        case ResourceUnit.HECTARE:
            return "Hektar";
        case ResourceUnit.SQUARE_METER:
            return "Quadratmeter";
        case ResourceUnit.QUINTAL:
            return getSingularPluralTextFormatter("Dezitonne", "Dezitonnen")(count);
        case ResourceUnit.KILO_WATT_HOUR:
            return getSingularPluralTextFormatter("Kilowattstunde", "Kilowattstunden")(count);
        case ResourceUnit.MEGA_WATT_HOUR:
            return getSingularPluralTextFormatter("Megawattstunde", "Megawattstunden")(count);
        case ResourceUnit.FEE:
            return getSingularPluralTextFormatter("Gebühr", "Gebühren")(count);
        case ResourceUnit.UNIT:
            return getSingularPluralTextFormatter("Einheit", "Einheiten")(count);
        case ResourceUnit.FLATRATE:
            return getSingularPluralTextFormatter("Pauschale", "Pauschalen")(count);
    }
};
