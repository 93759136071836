import { lazy } from "react";
import { withOnlyImplicitOwner } from "@/components/authentication/Session/withOnlyImplicitOwner";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const MaschinenringDetailsPageContent = lazy(() => import("./MaschinenringDetailsPageContent"));

function MaschinenringDetailsPageInternal() {
    return (
        <DefaultPage>
            <MaschinenringDetailsPageContent />
        </DefaultPage>
    );
}

export const MaschinenringDetailsPage = withOnlyImplicitOwner()(MaschinenringDetailsPageInternal);
