import { Device, DeviceInfo } from "@capacitor/device";
import { useEffect, useState } from "react";

export function useDeviceInfo() {
    const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | null>(null);
    useEffect(() => {
        const getDeviceInfo = async () => {
            try {
                const deviceInfo: DeviceInfo = await Device.getInfo();
                setDeviceInfo(deviceInfo);
            } catch (error) {
                // could happen, not critical
            }
        };
        getDeviceInfo();
    }, []);
    return deviceInfo;
}
