import { lazy } from "react";
import { withOnlyImplicitOwner } from "@/components/authentication/Session/withOnlyImplicitOwner";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const MaschinenringsPageContent = lazy(() => import("./MaschinenringsPageContent"));

function MaschinenringsPageInternal() {
    return (
        <DefaultPage>
            <MaschinenringsPageContent />
        </DefaultPage>
    );
}

export const MaschinenringsPage = withOnlyImplicitOwner()(MaschinenringsPageInternal);
